.password-change-page {
  margin: auto;
  text-align: center;

  .title {
    @include typo-heading(3.2rem);
    margin-top: $xl-space;
  }

  .sub-title {
    margin-top: $md-space;
  }

  .password-change-form {
    margin: $md-space auto;
    width: 30rem;

    .pintextinput-head {
      height: 0;
    }
  }

  .submit-button {
    width: 30rem;
  }

}
