.chat-container {

	.chat-notifications-container {
		padding: 0 $sm-space;

		.chat-notification {
			padding: $sm-space;
			margin: $xs-space 0;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
			background: $smoke;
			border-radius: $md-radius;
			.delimiter {
				flex-shrink: 0;
				width: 4px;
				border-radius: $md-radius;
			}

			.text {
				@include typo-default($size: 1.2rem, $color: $coal);
				margin-right: $sm-space;

				strong {
					font-weight: $bold;
				}
			}

			&:first-child {
				margin-top: $sm-space;
			}

			&.closed {
				animation: chat-notification-closing 0.4s ease-in-out forwards;
			}

			&.persona {
				background-color: scale-color($mustaYellow, $lightness: 80%);
			}

			@keyframes chat-notification-closing {
				0% { opacity: 1; }
				100% { opacity: 0; display: none !important; }
			}
		}
	}

	.chat-content {
		padding: 0 $md-space;
		overflow-y: scroll;
		max-height: 100%;

	}

	.chat-footer {
		margin-top: auto;
		padding: 0 $sm-space;

		.chat-footer-container {
			display: flex;
			padding: $sm-space $sm-space;
			border-top: solid 1px $silver;


			.input {
				border: none;
				width: 100%;
				resize: none;
				margin-right: $sm-space;
				font-size: 1.4rem;
				color: $coal;
				max-height: calc(50vh - 10rem);
				overflow-y: scroll;

				&::placeholder {
					color: $silver;
				}

				&:focus {
					outline: none;
				}

				&::-webkit-scrollbar {
					-webkit-appearance: none;
					width: 7px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 4px;
					background-color: rgba(0,0,0,.5);
					box-shadow: 0 0 1px rgba(255,255,255,.5);
				}
			}
		}
	}

	.chat-opt-out-view {
		margin-top: auto;
		padding: $md-space;
		border-top: solid 1px $silver;
		background-color: $smoke;
		.opt-out-title {
			@include typo-default();
			margin-bottom: $xs-space;
		}
		.opt-out-message {
			@include typo-default(1.2rem, $color: $nickel);
		}
	}

}
