.waiting-list {


	.title-container {


		.counter-container {
			background: $snow;
			border-radius: $md-radius;
			padding: 0.01rem $sm-space;
			@include typo-default(2.4rem, $semibold);
		}
	}

	.waiting-box {
		background-color: $snow;
		border-radius: $md-radius;
		padding: $md-space;
		margin-right: $md-space;
		@include shadow-light();

		&.active {
			@include shadow-heavy();
		}
	}

}
