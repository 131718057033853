.dashboard-illustration-container {
	padding: 0 $md-space;

	.dashboard-illustration-frame {
		width: 100%;
		height: 100%;

		img.illusration {
			border-radius: $lg-radius;
			border: solid $snow 1px;
			max-width: 100%;
			background-color: $snow;
		}
	}
}
