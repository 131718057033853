.user-menu-item {
	position: relative;
	cursor: pointer;
}

.user-menu-item .user-menu-item-static {
	display: flex;
	align-items: center;
	margin: 0 $sm-space;

	.user-menu-name {
		@include typo-default($color: $silver);
	}

	.user-menu-item-icon {
		background: $mustaYellow;
		border-radius: 50%;
		width: 2.8rem;
		height: 2.8rem;
		display: none;
		justify-content: space-around;
		align-items: center;
		@include typo-default($size: 1.2rem, $color: $coal);
		line-height: 1.8rem;
	}
}

.user-menu-item .user-menu-item-actions {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;

	i {
		display: none;
		margin-left: $xs-space;
	}

	.user-menu-item-action {
		display: flex;
		justify-content: space-between;
		margin-bottom: $sm-space;
		padding: $xs-space 0;
		@include typo-default($size: 1.2rem, $color: $coal);
	}

	.user-menu-item-action:last-child {
		margin-bottom: 0;
	}
}

.user-menu-item.closed .user-menu-item-actions {
	opacity: 1;
	max-height: 1000px;
}

.user-menu-item.open .user-menu-item-actions {
	opacity: 1;
	max-height: 1000px;
}

@keyframes show {
	0% { opacity: 0; max-height: 0; border-width: 0; }
	1% { opacity: 0; max-height: 1000px; margin-top: $sm-space; padding: $md-space; border-width: 1px; }
	100% { opacity: 1; max-height: 1000px; margin-top: $sm-space; padding: $md-space; border-width: 1px; }
}

@keyframes hide {
	0% { opacity: 1; max-height: 1000px; margin-top: $sm-space; padding: $md-space; border-width: 1px; }
	99% { opacity: 0; max-height: 1000px; margin-top: $sm-space; padding: $md-space; border-width: 1px; }
	100% { opacity: 0; max-height: 0; padding: 0; margin-top: 0; border-width: 0; }
}

.user-menu-item.open .user-menu-item-static .user-menu-item-state-indicator {
	transform: rotate(180deg);
}

@media (min-width: 768px) {

	.user-menu-item .user-menu-item-static {
		margin: 0 $sm-space;

		.user-menu-item-icon {
			display: flex;
		}
	}

	.user-menu-item .user-menu-item-actions {
		width: 16rem;
		position: absolute;
		margin-top: $sm-space;
		padding: $md-space;
		background: $snow;
		border-radius: 8px;
		top: 140%;
		right: 0;
		@include shadow-light();
		border: 1px solid $silver;
		opacity: 0;
		max-height: 0;

		i {
			display: initial;
		}

		.user-menu-item-action {
			display: flex;
			justify-content: space-between;
			margin-bottom: $sm-space;
			padding: 0;
			@include typo-default(1.2rem);
		}
	}

	.user-menu-item.new .user-menu-item-actions, .user-menu-item.closed .user-menu-item-actions {
		opacity: 0;
		max-height: 0;
		padding: 0;
		margin-top: 0;
		border-width: 0;
	}

	.user-menu-item.closed .user-menu-item-actions {
		animation: hide .2s ease-in-out;
	}

	.user-menu-item.open .user-menu-item-actions {
		animation: show .2s ease-in-out;
	}

}
