.script-overlay {
	position: fixed;
	z-index: 1000;
	top: -90vh;
	left: 0;
	@extend .col-6;
	@extend .offset-6;
	margin-top: 10.5rem;
	height: calc(100% - 21.5rem);
	background-color: $snow;
	border-left: solid $silver 1px;
	padding: 0 1rem;

	iframe {
		width: 100%;
		height: 100%;
		z-index: 1009;
	}

	.refresh-button {
		position: absolute;
		right: $md-space;
		bottom: $md-space;
		z-index: 1010;
		min-width: 10rem;
	}

	.loading-script {
		z-index: -1;
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
	}
}

.script-overlay.closed {
	animation: script-overlay-closing 0.4s ease-in-out 0s forwards;
}

.script-overlay.opened {
	animation: script-overlay-opening 0.4s ease-in-out 0s forwards;
}

.script-overlay.closed.noanim {
	animation: none;
}

@keyframes script-overlay-closing {
	0% { top: 0; }
	99% { top: -90vh; opacity: 1; }
	100% { top: -90vh; opacity: 0; }
}

@keyframes script-overlay-opening {
	0% { top: -90vh; opacity: 1; }
	100% { top: 0; opacity: 1; }
}
