.chat-room {

	.chat-header {
		z-index: 1030;
		position: relative;
		background: $smoke;
		border-bottom: solid 1px $silver;
		padding: $sm-space $md-space;

		.main-iframe {
			border: none;
			min-height: 60rem;
		}

		.context-content {

			.delimiter {
				width: 70%;
				margin: $md-space auto 0;
				border-bottom: solid 1px $silver;
			}
		}

		.resign-engagement-button {
			min-width: initial;
		}
	}

	.chat-room-content {
		height: calc(100% - 6rem);
	}
}
