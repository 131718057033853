.rounded-icon-button {
	background-color: $snow;
	line-height: 1;

	&:focus {
		outline: none;
	}

	$spaces: (
		"xs": 0.1rem,
		"sm": $xs-space,
		"md": $sm-space,
		"lg": $md-space,
		"xl": $lg-space,
	);

	@each $key, $space in $spaces {
		&.#{$key} {
			padding: $space;
		}
	}

	@each $key, $color in $colors {
		&.#{$key} {
			border: solid 2px $color;
		}
	}

	&.placeholder {
		width: 2.8rem;
		height: 2.8rem;
	}

}
