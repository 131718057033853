.form-input-container {

	padding-top: $md-space;
	padding-bottom: $md-space;

	.form-label {
		@include typo-default($color: $nickel);

		&:not(.light) {
			font-weight: 600;
		}
	}

	.input {
		width: 100%;

		&.input-text {
			border: none;
			margin-bottom: $sm-space;
			border-bottom: solid 1px $silver;
			padding: $xs-space;
			@include typo-default(1.2rem, $semibold, $coal);
			transition: border .3s ease-in-out;
			min-height: 2.6rem;

			&.valid {
				border-bottom: solid 1px $mustaYellow;
			}

			&.error, &.key-pressed-error {
				border-bottom: solid 1px $failure;
			}

			&::placeholder {
				color: $silver;
			}

			&:disabled {
				color: transparentize($nickel, 0.2);
				cursor: not-allowed;
			}
		}

		&.input-choices {

			.choice {
				width: 48%;
				margin-bottom: $xs-space;

				&.one-line {
					width: 100%;
				}

			}

		}

		&.input-open-choices {
			border: none;
			padding: $xs-space;
			background: none;
			@include typo-default(1.2rem, $semibold, $coal);
			min-height: 2.6rem;
			&::placeholder {
				color: $silver;
			}
		}

		&.input-date {
			.calendar-toggle {
				margin-top: 0.7rem;
			}
		}

		&:focus, .pinbutton:focus {
			outline: none;
		}
	}

	.open-choices-input-container {
		border: solid 1px $silver;
		padding-right: $xs-space;
		border-radius: $md-radius;
	}

	.dismiss-button {
		padding: 0 $sm-space;
	}
}
