.overlay-card-container {
	position: fixed;
	z-index: 3000;
	top:0;
	left: 0;
	@extend .full-vh;
	@extend .full-vw;
	background: transparentize($snow, 0.20);
	overflow-y: scroll;

	.overlay-card {
		width: 70%;
		margin: auto;
		margin-bottom: 14rem;
		background: $snow;
		padding: $md-space;
		border-radius: $lg-radius;
		@include shadow-medium();

		.header {
			border-bottom: solid 1px $silver;
			padding: $md-space;
		}

		.content {
			width: 100%;
		}
	}
}

.overlay-card-container.noScroll {
	overflow: unset !important;
}

$overlayCardAnimDuration: 0.3s;

.overlay-card-container.closed {
	width: 0;
	height: 0;
	animation: pinpopup-closing $pinpopupAnimDuration ease-in-out 0s forwards;

	.overlay-card {
		animation: overlay-card-container-content-closing $overlayCardAnimDuration ease-in-out 0s forwards;
	}
}

.overlay-card-container.closed.noanim {
	animation: none;
	opacity: 0;
	overflow: unset;
	margin-top: 100vh;

	.overlay-card {
		animation: none;
	}
}

.overlay-card-container.opened {
	&.noScroll {
		animation: overlay-card-container-opening-unset $pinpopupAnimDuration ease-in-out 0s forwards;
	}
	&:not(.noScroll) {
		animation: overlay-card-container-opening-scroll $pinpopupAnimDuration ease-in-out 0s forwards;
	}


	.overlay-card {
		animation: overlay-card-container-content-opening $overlayCardAnimDuration ease-in-out 0s forwards;
	}
}

@keyframes overlay-card-container-opening-unset {
	0% { width: 100vw; height: 100vh; opacity: 0; overflow: unset; }
	100% { width: 100vw; height: 100vh; opacity: 1; overflow-y: unset; }
}

@keyframes overlay-card-container-opening-scroll {
	0% { width: 100vw; height: 100vh; opacity: 0; overflow: unset; }
	100% { width: 100vw; height: 100vh; opacity: 1; overflow-y: scroll; }
}

@keyframes overlay-card-container-content-opening {
	0% { margin-top: 100vh; }
	100% { margin-top: 14rem; }
}

@keyframes overlay-card-container-content-closing {
	0% { margin-top: 14rem; }
	100% { margin-top: 100vh; }
}
