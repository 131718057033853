.footer-container {
	position: fixed;
	z-index: 1029;
	left: 0;
	bottom: 0;
	width: 100vw;
	height: $xl-space;
	padding: 0.6rem $sm-space;
	background: $smoke;
	border-top: solid 1px $silver;

	.footer-conversation {
		overflow-x: hidden;
	}


	.name-content {
		padding: $xs-space;
		padding-bottom: 0;
		margin-bottom: $xs-space;
		height: 2.1rem;
		color: $coal;
		flex-shrink: 10;

		&.name-action {
			max-width: calc(100% - 1.6rem);
		}

		.name {
			display:block;
			font-size: 1.3rem;
			line-height: 1.8rem;
			min-width: 7rem;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			flex-shrink: 10;
		}

		.timer {
			overflow-x: hidden;
		}
	}

	.footer-bubble-container {
		flex: 1 0;
		min-width: 23.5rem;
		max-width: 23.5rem;

		a {
			text-decoration: none;
			max-width: inherit;
		}

		.engagement-bubble {
			background-color: scale-color($color: $silver, $lightness: 40%);
			border-radius: $sm-radius;
			max-width: inherit;

			.name-content, .message-content {
				@include typo-default(1.2rem, $color: $coal);
			}

			.message-content {
				border-radius: $sm-radius;
				margin-top: auto;
				margin-left: 0.2rem;
				margin-right: 0.2rem;
				margin-bottom: 0.2rem;
				height: 2rem;
				padding: 0.1rem $xs-space;
				color: $snow;

				&.sender-lead {
					background-color: $failure;
				}

				&.sender-operator, .sender-bot {
					background: $nickel;
				}

				&.current {
					background: $success;
				}
			}
		}
	}

	.footer-collapsed-container {
		.collapsed-button {
			//pointer radius-sm d-flex align-items-center justify-content-center full-height pr-sm pl-sm
			cursor: pointer;
			border-radius: $sm-radius;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 $sm-space;
			background-color: scale-color($color: $silver, $lightness: 40%);
		}

		.footer-collapsed {
			@extend .md-radius;
			background: $snow;
			@include shadow-heavy();
			position: absolute;
			width: calc(23.5rem + 2rem);
			// One cell plus padding
			min-height: calc(5.7rem + (2 * 0.5rem));
			// Full size minus navbar height, footer height and padding
			max-height: calc(100vh - (6rem + 8.5rem + 2rem));
			padding: $sm-space;
			overflow-y: scroll;
			overflow-x: hidden;


		// Special navbar settings for MacOS and Chrome
		&::-webkit-scrollbar {
				-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
				width: 0.95rem;
		}

		&::-webkit-scrollbar-thumb {
				border-radius: 8px;
				border: 2px solid white; /* should match background, can't be transparent */
				background-color: rgba(0, 0, 0, .5);
		}


			@media screen and (min-width: 40rem) {
				margin-left: calc((23.5rem + 2rem - 5.2rem) / (-2));
			}

			.footer-bubble-container {
				display: table !important;
				&:not(:last-child){
					margin-bottom: $sm-space;
				}
			}

			&.closed {
				animation: footer-collapsed-closing 0.2s ease-in-out 0s forwards;
			}

			&.opened {
				animation: footer-collapsed-opening 0.2s ease-in-out 0s forwards;
			}

			&.closed.noanim {
				animation: none;
				opacity: 0;
			}

			@keyframes footer-collapsed-closing {
				0% { bottom: 7rem; }
				99% { bottom: 6rem; opacity: 0; }
				100% { bottom: initial; opacity: 0; }
			}

			@keyframes footer-collapsed-opening {
				0% { bottom: initial; opacity: 0; }
				1% { bottom: 6rem; opacity: 0; }
				100% { bottom: 7rem; opacity: 1; }
			}
		}
	}

	.footer-action-container {

		.footer-action {
			border-radius: $sm-radius;
			background: white;
			width: 18.4rem;
			height: 100%;
			padding: 0 0.2rem;

			&.placeholder {
				cursor: default;
			}

			&:not(.placeholder) {
				@include shadow-light();
			}

			.pinbutton  {
				min-width: initial;
			}
		}
	}

}

.footer-placeholder {
	height: $xl-space;
	flex-shrink: 0;
}
