.qualification-work-space {
	border-left: solid $silver 1px;
	@include shadow-medium();

	.pintabs {

		.pintabs-header {
			margin: 0 $sm-space;
			z-index: 1020;
			background-color: $snow;

			.pintab {

				padding-top: $sm-space;
				margin-left: $sm-space;
				@include typo-default($color: $silver);
				font-weight: 600;

				&.active {
					color: $coal;
				}
			}

			.saving-animation.hidden {
				display: none !important;
			}

			.script-button {
				position: fixed;
				z-index: 1019;
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
				margin-top: 4.4rem;
				right: $md-space;
				min-width: initial;

				&.is-open {
					background-color: $snow !important;
					border-top: solid 1px $silver;
				}
				&:focus {
					outline: none;
				}
			}

		}

		.pintabs-content {
			overflow-y: scroll;
			padding: 0 $md-space;
			margin-bottom: 5rem;
		}
	}

	.recap-overlay {
		.block {
			padding-top: $sm-space;
			padding-bottom: $lg-space;

			.delimiter {
				margin-top: $lg-space;
				width: 6rem;
				height: 1px;
				background-color: $silver;
			}
		}
	}
}
