.expendable-icon-button.pinbutton {
	border-radius: 3rem;
	max-width: 3rem;
	height: 3rem;
	transition: max-width 0.7s ease-in-out;
	display: flex;
	align-items: center;
	min-width: initial;
	padding: $xs-space;

	.icon {
		overflow: visible;
	}

	.text {
		display: inline;
		overflow: hidden;
		visibility: hidden;
		opacity: 0;
		padding: 0 $sm-space;
  	transition: visibility 0.3s ease-in-out 0.3s, opacity 0.3s linear 0.3s;
		@include typo-default(1.2rem, $semibold, $snow);
	}

	&:hover:not(:disabled), &.hovered:not(:disabled) {
		width: fit-content;
		max-width: 50rem;
		.text {
			visibility: visible;
  		opacity: 1;
		}
	}
	&:focus {
		outline: none;
	}
}
