.signup-page {
	margin: auto;
	text-align: center;

	.title {
		@include typo-heading(3.2rem);
		margin-top: $xl-space;
	}

	.sub-title {
		margin-top: $md-space;
	}

	.signup-form {
		margin: $md-space auto;
		width: 30rem;

		.pintextinput-head {
			height: 0;
		}
	}

	.submit-button {
		width: 30rem;
	}

	.legal {
		@include typo-default(1.2rem, $regular, $nickel);
	}
}
