.pinprogress-bar-container {
	display: flex;
	align-items: baseline;

	.pinprogress-bar {
		width: 100%;
		height: 4px;
		border-radius: 2px;
		background-color: $silver;

		.pinprogress-bar-content {
			height: inherit;
			border-radius: 2px;
		}
	}
}
