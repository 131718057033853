.card-smooch {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $silver;
	border-radius: $sm-radius;
	overflow: hidden;

	iframe {
		bottom: 0 !important;
		right: 0 !important;
	}
}
