.calendar {
	.calendar-pop-in-header {
		font-size: 1.6rem;
		font-weight: $semibold;
		border-bottom: 1px $silver solid;
		padding-bottom: 1rem;
		margin-bottom: 2rem;
	}

	.calendar-pop-in-body {
		display: flex;
		align-items: flex-start;

		.slots {
			width: 25rem;
			margin-left: 2rem;
			font-size: 1.2rem;
			line-height: 1.8rem;
			font-weight: $semibold;

			.day-label {
				text-transform: uppercase;
				color: $nickel;
				padding-bottom: 1rem;
			}

			.slots-list {
				overflow: scroll;
				max-height: 30rem;

				.slot {
					text-align: center;
					border: 1px solid $silver;
					border-radius: $md-radius;
					padding: $xs-space;
					margin-bottom: $xs-space;

					.slot-duration {
						color: $moon;
					}

					&.pointer{
						cursor: pointer;
					}

					&.selected {
						background-color: $mustaYellow;
						color: $snow;

						.slot-duration {
							color: $snow;
						}
					}
				}
			}

			.indication {
				font-weight: normal;
			}
		}

		.datepicker {
			@include typo-default();

			.DayPicker-Months {
				flex-wrap: nowrap;
			}
			.DayPicker-Week {
				//padding: $xs-space 0;
			}
			.DayPicker-Month {
				margin: 0;
			}
			.DayPicker-Day {
				border-radius: 100%;
			}
			.DayPicker-Day--available {
				background-color: transparentize($mustaYellow, 0.8);
			}
			.DayPicker-Day--selected {
				background-color: $nickel;
			}
			.DayPicker-Day--selected.DayPicker-Day--available {
				background-color: $mustaYellow;
			}
			.DayPicker-Day--outside {
				background: none;
			}
		}
	}

}
