.display-error-component {
	display: flex;
	align-items: flex-end;
	padding: $md-space;
	background-color: transparentize($failure, 0.85);
	border-radius: $sm-radius;

	&.small {
		font-size: 1.4rem;
	}
}
