.analytics {
	@media screen and (max-width: 992px) {
		margin-top: $sm-space;
	}
}

.dashboard-analytics-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $mustaYellow;
	border-radius: $md-radius;
	padding: $sm-space;
	@include mustaYellow-shadow();

	.main-content {
		color: $snow;

		.title {
			@include typo-default($size: 2.4rem, $weight: $semibold, $color: $snow);
		}

		.subtitle {
			@include typo-default($size: 1.2rem, $color: $snow);
		}

		.content {
			@include typo-default($size: 2.4rem, $weight: $semibold, $color: $spicyRed);
		}
	}

	.icon-content {
		background-color: darken($mustaYellow, 10%);
		border-radius: $md-radius;
		padding: $md-space;
	}
}
