.pulsing-error-pill {
  border: 1px solid transparentize($nickel, 0.75);
  background: $snow;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $xs-space 0 $xs-space;

  &:hover {
    text-decoration: none;
  }

  .text {
    @include typo-default(1.2rem);
    color: $failure;
    font-weight: $semibold;
    margin: 0 $xs-space;
  }

  .animated-dot {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    background-color: transparentize($failure, 0.8);
    border-radius: 50%;
    margin: .2rem .2rem .2rem 0;

    .dot-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .scaling-dot {
        border-radius: 50%;
        background-color: $failure;
        width: 100%;
        height: 100%;
      }
    }

    &.inactive .dot-container .scaling-dot {
      visibility: hidden;
    }
  }
}

.pulsing-error-pill .animated-dot .scaling-dot {
  animation: dot-scale-animation 1.9s infinite;
  animation-timing-function: ease-in;
  transform: scale(0);
}

@keyframes dot-scale-animation {
  0%		{ transform: scale(0);		opacity: 1;		}
  70%		{ transform: scale(1);		opacity: 0.3;	}
  100%	    { transform: scale(1);		opacity: 0;		}
}
