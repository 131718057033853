.help-content .help-card, .help-content .resign-card {
	position: relative;
	margin: $md-space 0;
	padding: $md-space;
	border-radius: 8px;
	background-color: $smoke;
	@include shadow-light();

	.help-card-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.help-card-tags {
		display: flex;
		justify-content: flex-end;
		margin-bottom: $sm-space;
	}

	.help-card-title {
		@include typo-heading(2.4rem, $semibold);
		margin-bottom: $md-space;
	}

	.help-card-content p:last-child {
		margin: 0;
	}

	/* Guru iFrames */
	.ghq-card-content__iframe {
		width: 100%;
	}
}

.help-content .resign-card {
	.resign-card-title {
		@include typo-heading(2rem, $semibold);
		margin-bottom: $md-space;
	}

	.resign-card-disclosure {
		@include typo-default();
		margin-bottom: $md-space;
	}

	.resign-card-legend {
		@include typo-default(1.2rem, $color: $coal);
		margin-bottom: $xs-space;
	}

	.resign-card-reason {
		width: 100%;
		min-height: 10rem;
		border-radius: $sm-radius;
		padding: $sm-space;

		&:focus {
			outline: none;
		}
	}

	.button-wrapper {
		margin-top: $sm-space;
		display: flex;
		justify-content: flex-end;
	}
}

.help-content .resign-card {
	margin-bottom: 0;
}

.guru-error {
	display: flex;
	align-items: center;
	@include typo-default($color: $failure);

	span {
		margin-left: $xs-space;
	}
}

.guru-search-bar {
	position: relative;
	display: flex;
	align-items: center;

	.search-input {
		margin-bottom: 0;
		flex: 1;

		input {
			padding: $sm-space;
			padding-left: 4.4rem;

			&:focus {
				outline: none;
			}
		}

		.pintextinput-head {
			display: none;
		}
	}

	.search-button {
		position: absolute;
		left: $sm-space;
		font-size: 2.4rem;
		color: $coal;
	}

	.search-clear {
		position: absolute;
		right: $sm-space;

		&.hidden {
			display: none;
		}
	}
}

/* Guru styles */
.ghq-card-content__block-quote {
	font-style: italic;
	margin: $md-space 0;
	padding: $sm-space 0 $sm-space $md-space;
	color: $coal;
	border-left: solid 2px $silver;

	&::before, &::after {
		font-family: Georgia, 'Times New Roman', Times, serif;
		font-size: 2.4rem;
	}
	&::before {
		padding-right: $xs-space;
		content: "“";
	}
	&::after {
		padding-left: $xs-space;
		content: "”";
	}
}

.ghq-card-content__guru-card {
	color: $mustaYellow;

	&:hover {
		color: $info;
	}
}

.ghq-card-content__large-heading {
	@include typo-heading(1.8rem);
	margin: $md-space 0;
}

.ghq-card-content__medium-heading {
	@include typo-heading(1.8rem, $semibold);
	font-weight: $bold;
	margin: $sm-space 0;
}
.ghq-card-content__small-heading {
	@include typo-heading(1.8rem, $semibold);
	margin: $sm-space 0;
}
.ghq-card-content__paragraph {
	margin: $xs-space 0;
}
.ghq-card-content__code-block {
	padding: $sm-space;
	box-shadow: 0 0 4px 1px $silver inset;
	background: $coal;
	border: 1px solid $silver;
	border-radius: $md-radius;
	margin: $sm-space 0;

	.ghq-card-content__code-block-line {
		font-family: monospace;
		font-size: 1.8rem;
		color: $snow;
	}
}

.ghq-card-content__video-responsive-wrapper {

	.ghq-card-content__video {
		width: 48rem;
		height: 27rem;
	}
}

.ghq-card-content__table {
	.ghq-card-content__table-body {
		border: 1px solid $coal;

		.ghq-card-content__table-cell {
			border: 1px solid $coal;
			padding: $xs-space .6rem;
		}
	}
}
