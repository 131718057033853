$shimmerBackgrounds: (
	"water": scale-color($color: $silver, $lightness: 60%),
	"stream": scale-color($color: $silver, $lightness: 20%)
);

@each $key, $value in $shimmerBackgrounds {

	.shimmer-background-#{$key} {
		animation-duration: 2.5s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: ease-in-out;
		-webkit-animation-duration: 2.5s;
		-webkit-animation-fill-mode: forwards;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: placeHolderShimmer;
		-webkit-animation-timing-function: ease-in-out;
		background: $value;
		background-color: $value;
		background-image: linear-gradient(to right, $value 0%, #ECEFF2 20%, $value 40%, $value 100%);
		background-repeat: no-repeat;
		background-size: 400px 100%;
		position: relative;

		$mapSize: (
			"100": 100%,
			"90": 90%,
			"75": 75%,
			"50": 50%,
			"25": 25%
		);

		@each $key, $value in $mapSize {
			&.content-#{$key} {
				width: $value;
				height: 1.4rem;
				border-radius: $sm-radius;
			}
		}
	}

}

@keyframes placeHolderShimmer{

	0% {
	    background-position: -400px 0;
	}
	100% {
	    background-position: calc(100% + 400px) 0;
	}
}

.shimmer-background {
	@extend .shimmer-background-water
}
