.validation-bar {
	padding: $sm-space $sm-space 0 $sm-space;
	position: fixed;
	z-index: 1010;
	bottom: 6rem;
	height: 6rem;
	left: 0;
	@extend .col-6;
	@extend .offset-6;
	background-color: $snow;
	border-left: solid $silver 1px;

	.delimiter {
		border-top: solid $silver 1px;
	}
}
