.app-header {
	padding: 0 $md-space;
	@include shadow-light();
	height: 6rem;
	background: $snow;

	.app-header-logo {
		padding: 0;
		margin-right: 0;
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		flex: 1;
	}

	.app-header-agency-name {
		@include typo-default($moon);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.app-header-menus-container {
		height: 100%;
		box-shadow: none;
		padding: 0;
		border-radius: 0;
		background: $snow;
		flex: 2;
	}

	.app-header-center-menu, .app-header-right-menu {
		flex: 1;
	}

	.app-header-center-menu {
		justify-content: space-around;

		.app-header-center-menu-items-wrapper {
			display: flex;
		}

		.app-header-menu-item {
			padding: $xs-space 0;
		}
	}

	.app-header-right-menu {
		align-items: center;
		justify-content: flex-end;
		height: 100%;
	}

	.app-header-right-menu .app-header-menu-item:hover {
		color: inherit;
	}

	.app-header-right-menu .app-header-menu-item.disabled {
		@include typo-default(1.2rem, $regular, $nickel);
	}
}

.app-header-placeholder {
	height: 6rem;
	flex-shrink: 0;
}

@media (max-width: map-get($grid-breakpoints, md)) {
	.app-header-agency-name {
		display: none;
	}
}
