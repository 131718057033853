.additional-info-page {
  margin: auto;
  text-align: center;

  .title {
    @include typo-heading(2.4rem);
    margin-top: $xl-space;
  }

  .sub-title {
    margin-top: $md-space;
  }

  .info-form {
    margin: $md-space auto;
    width: 60rem;
  }

  .submit-button {
    width: 30rem;
  }

  .legal {
    @include typo-default(1.2rem, $regular, $nickel);
  }
}
