body {
	.dashboard {
		background-color: $smoke;
	}
}

@mixin mustaYellow-shadow {
	box-shadow: 0 14px 14px 0 rgba(0,0,0,0.06), 0 14px 14px 0 rgba(242,189,66,0.3);
}

.f-bold {
	font-weight: 600;
}


.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.pointer {
	cursor: pointer;
}

.no-outline:focus {
	outline: none;
}

.mv-xxxs {
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
}

.p-xxs {
	padding: 0.2rem;
}

.rounded-circle {
	$sizes: (
		s-sm: 0.4rem,
		s-md: 0.8rem,
		s-lg: 1.6rem,
		s-xl: 2rem
	);

	@each $key, $size in $sizes {
		&.#{$key} {
			width: $size;
			height: $size;
		}
	}
}

.pinfa {
	@each $key, $color in $colors {
		&.#{$key} {
			color: $color;
		}
	}
}

.pintoast.pintoast-warning {
	.pintoast-content .pintoast-content-subtext {
		color: $coal;
	}
}
